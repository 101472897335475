<template>
    <div id="MainLayout" class="h-screen w-screen z-50" >
      <LeaveCommentDraft v-show="notifications.leaveCommentDraft" />
      <NetworkError v-show="notifications.networkError" />
      <LeaveCreateForm v-show="notifications.leaveCreateForm" />
      <LeaveSettings v-show="notifications.leaveSettings" />
      <img src="/img/bg-start.png"
           v-if="(!generalApp.isAuthorized)&&(route.path === '/')"
           class="absolute z-[0] opacity-30 w-full h-full"
           alt="">
      <div
          id="TopNav"
          class="fixed bg-black z-50 p-2 pl-[15px] flex flex-col items-center justify-center w-full min-h-[50px] max-h-[52px] overflow-y-auto"
          v-if="(generalApp.isAuthorized)||(route.path !== '/')"
      >
        <TopBar />
      </div>
      <div class="flex w-full max-w-[500px] mx-auto h-screen relative">
        <slot />
      </div>
      <div v-if="!generalApp.isAuthorized"
          class="fixed z-50 bottom-2 flex w-full flex-col bg-transparent "
      >
        <UnauthorizedUserMenu />
      </div>
      <div class="fixed bottom-[75px] left-[calc(50%-135px)] z-50 bg-transparent" v-if="generalApp.isAuthorized">
        <SendNewTicket v-if="route.path === `/my-tickets/create-new` && !generalApp.ticketDone" />
        <NewTicket v-if="route.path === `/my-tickets` || route.path === `/` || route.path === `/assignments`" />
      </div>
      <div v-if="(generalApp.isAuthorized)&&(route.path.split('/')[1] !== 'user')"
          class="fixed bottom-0 z-20 flex w-full flex-col bg-transparent specialShadow"
      >
        <AuthorisedUserMenu />
      </div>
    </div>
</template>

<script setup>
import AuthorisedUserMenu from "~/components/NavigationMenu/AuthorisedUserMenu.vue";
import UnauthorizedUserMenu from "~/components/NavigationMenu/UnauthorizedUserMenu.vue";
import TopBar from "~/components/NavigationMenu/TopBar.vue";
import {useGeneralApp} from "~/stores/generalApp.js";
import NewTicket from "~/components/NavigationMenu/NewTicket.vue";
import SendNewTicket from "~/components/NavigationMenu/SendNewTicket.vue";
import {useUserStore} from "~/stores/user.js";
import NetworkError from "~/components/Notifications/NetworkError.vue";
import {useNotifications} from "~/stores/notifications.js";
import LeaveCreateForm from "~/components/Notifications/LeaveCreateForm.vue";
import LeaveSettings from "~/components/Notifications/LeaveSettings.vue";
import LeaveCommentDraft from "~/components/Notifications/leaveCommentDraft.vue";
const userStore = useUserStore()
const generalApp = useGeneralApp()
const notifications = useNotifications()
const route = useRoute()
/*const generalApp = ref()
const mounted = ref(false)
onMounted(async () => {
  generalApp.value = await useIDB().general.getItem('general')
  console.log(generalApp.value.isAuthorized)
  mounted.value = true
})*/
</script>

<style scoped>
  .specialShadow {
    box-shadow: 0px -5px 10px 0px #0000001A !important;
  }
</style>